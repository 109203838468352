import request from '@/utils/request'

// 手机验证码登录
export function login(data) {
    return request({
        url: '/app/login',
        method: 'post',
        data
    })
}

// 获取验证码
export function getSmsCode(query) {
    return request({
        url: '/app/getCode',
        method: 'get',
        params: query
    })
}

// 获取用户信息
export function getUserInfo(query) {
    return request({
        url: '/user/getUser',
        method: 'get',
        params: query
    })
}