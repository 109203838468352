<template>
    <div class="first-contain-box" :style="{ backgroundImage: hasLogin ? getBgi(hasLoginBg) : getBgi(notLoginBg) }">

        <!-- 导航栏 -->
        <div class="nav-box df ai-c">
            <div class="head-box">
                <!-- <img :src="head" width="100%" height="100%" @onerror="headErr" /> -->
                <el-image :src="head" style="width: 100%; height: 100%;"></el-image>
            </div>
            <div class="nav-box-info df fd-c">
                <div class="nbi-top">{{ loginName }}</div>
                <div class="nbi-item-list">
                    <div class="nbi-item df ai-c jc-c">
                        {{ hasLogin ? '已登录' : '未登录' }}
                        <i class="el-icon-s-unfold" v-if="hasLogin"
                            style="font-size: 16px; margin-left: 10px; cursor: pointer;" @click="hideInfo = !hideInfo"
                            ref="userInfoBtn"></i>
                    </div>
                </div>
            </div>
        </div>

        <!-- 登录框 -->
        <div class="login-box df ai-c jc-c" v-if="!hasLogin">
            <!-- <div class="login-box df ai-c jc-c" v-if="false"> -->
            <div class="login-box-inside">
                <div class="lbi-title mg">欢迎来到家居元宇宙</div>
                <div class="df mg">
                    <div class="lbi-tag df fd-c ai-c">
                        <div>{{ formData.type == 1 ? '注册账号' : '登录账号' }}</div>
                        <div class="lbi-sld"></div>
                    </div>
                </div>
                <div class="input-box df jc-sb mg">
                    <input v-model="formData.phone" type="text" placeholder="请输入手机号" class="bg" style="width: 100%;">
                </div>
                <!-- <div class="input-box df jc-sb ai-c mg">
                    <input v-model="formData.verificationCode" type="text" placeholder="请输入验证码" class="bg"
                        style="width: 27.78vh;">
                    <el-button class="bg" style="width: 11.48vh; padding: 1.11vh 1.85vh;" :loading="loadBtn"
                        :disabled="countDown ? true : false" @click="sendCode">{{
                            countDown ? countDown + 's后发送' : '获取验证码' }}</el-button>
                </div> -->
                <div class="input-box df jc-sb ai-c mg">
                    <input v-model="formData.verificationCode" type="text" placeholder="请输入验证码" class="bg"
                        style="width: 27vh;">
                    <!-- <button class="bg" style="width: 124px; cursor: pointer;" @click="sendCode">获取验证码</button> -->
                    <el-button class="bg" style="width: 12.3vh; padding: 1.11vh 0.85vh;" :loading="loadBtn"
                        :disabled="countDown ? true : false" @click="sendCode">{{
                            countDown ? countDown + 's后发送' : '获取验证码' }}</el-button>
                </div>
                <div class="mg change-type" style="color: #E0E0E0; cursor: pointer;" v-if="false"
                    @click="formData.type == 1 ? formData.type = 2 : formData.type = 1">{{ formData.type == 1 ? '已有账号，去登录' :
                        '注册账号' }}</div>

                <div class="sb-out df jc-c" style="margin-top: 10.185vh;">
                    <!-- <div class="submit-btn df ai-c jc-c" @click="register">{{ formData.type == 1 ? '注册' : '登录' }}</div> -->
                    <el-button class="submit-btn" style="padding: 1.11vh 1.85vh;" :loading="loadLoginBtn"
                        @click="register">{{ formData.type == 1 ? '注册' :
                            '登录' }}</el-button>
                </div>
            </div>
        </div>

        <!-- 内容区 -->
        <div class="content-box" v-if="hasLogin">
            <!-- <div class="content-box" v-if="true"> -->
            <!-- 底层iframe -->
            <iframe scrolling="no" class="iframe-box" :src="src" v-if="true"></iframe>

            <!-- 左侧菜单 -->
            <div class="left-menu-box" :class="{ 'hide-lmb': !showBall }">
                <div class="menu-ball">
                    <img :src="menuBall" height="100%" width="100%" />
                    <div class="trg-btn" @click="showBall = !showBall"></div>
                </div>

                <div class="lmb-left"></div>
                <div class="menu-box">
                    <template v-for="(item, index) in topMenus">
                        <div class="top-menu" :class="{ active: curTopIndex == item.id }" :key="item.id"
                            v-if="curTopIndex == item.id || !showSub" @click="handlerNav('top', item, item.id)">{{ item.name
                            }}
                        </div>
                    </template>
                    <template v-if="showSub">
                        <div class="sub-menu" :class="{ active: curSubIndex == item1.id }"
                            v-for="(item1, index1) in subMenus" :key="item1.id" @click="handlerNav('sub', item1, item1.id)">
                            {{ item1.name }}</div>
                    </template>
                </div>
            </div>

            <!-- 右侧系统-视频 -->
            <div class="right-sys-box df jc-c" :class="{ 'hide-sys': !showSys, 'full-sys': !showBall && showSys }">

                <!-- 系统iframe -->
                <iframe scrolling="auto" v-if="!showVideo && !showYbjbz" class="iframe-sys-box" :class="{ h5: sysH5 }"
                    :src="sysSrc" frameborder="0" name="sysIframe" target="_blank"></iframe>

                <!-- 视频 -->
                <div class="video-box df fw-w" v-if="showVideo && !showYbjbz">
                    <div class="video-box-item" v-for="(item, index) in videoList" :key="index">
                        <video class="video-info" :controls="true" :autoplay="false" muted :poster="item.imagesUrl"
                            :src="item.videoUrl"></video>
                    </div>
                </div>

                <!-- 样板间布置 -->
                <div class="video-box df fw-w" v-if="showYbjbz && !showVideo"
                    style="background-color: #fff; align-items: flex-start; position: relative;">
                    <template v-if="ybjbzList.length">
                        <div class="df df fw-w"
                            style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; overflow-y: auto; ">
                            <div class="video-box-item df fd-c" style="width: 25%;" v-for="(item, index) in ybjbzList"
                                :key="index">
                                <!-- <img :src="item.url" width="100%" height="auto"> -->
                                <el-image :src="item.url" fit="cover" style="width: 100%; height: 80%; border-radius: 6px;"
                                    lazy></el-image>
                                <div class=""
                                    style="font-size: 18px; color: #000; padding: 6px 0; font-weight: 550; text-align: left;">
                                    {{
                                        item.name }}</div>
                                <div class="df ai-c ">
                                    <el-button style="padding: 6px; margin-right: 0px;"
                                        @click="goToIfr(1, item.designId)">去布设</el-button>
                                    <!-- <el-button style="padding: 6px; " @click="goToIfr(2, item.designId)">去浏览</el-button> -->
                                </div>
                            </div>
                        </div>

                    </template>

                    <div class="df ai-c jc-c" v-else
                        style="width: 100%; height: 100%; font-size: 50px; font-weight: 550; color: #000;">暂无数据</div>

                    <div class="ybjbzIframe"
                        style="width: 100%; height: 100%; position: absolute; top: 0; left: 0; overflow: hidden;"
                        v-if="showYbjbzIfr">
                        <el-button style="padding: 6px; position: absolute; top: 20px; left: 20px;"
                            icon="el-icon-arrow-left" @click="backYbjbz">返回列表</el-button>
                        <iframe scrolling="no" width="100%" height="100%" style="border: none;" :src="ybjbzSrc"
                            name="ybjbzIframe"></iframe>
                    </div>
                </div>

            </div>
        </div>

        <!-- 个人信息 -->
        <div class="userinfo-box" :class="{ 'hide-userInfo': hideInfo }">
            <el-button class="logout-btn" style="padding: 1.11vh 1.85vh;" :loading="loadLogoutBtn"
                @click="logout">退出登录</el-button>
        </div>

        <!-- 商城登录iframe -->
        <iframe scrolling="no" height="0" width="0" :src="shopUrl" v-if="true" name="shopIframe" ref="shopIframe"
            id="shopIframe"></iframe>

        <!-- 设计登录iframe -->
        <iframe scrolling="no" height="0" width="0" :src="sjUrl" v-if="true" name="sjIframe" ref="sjIframe"
            id="shopIframe"></iframe>

    </div>
</template>

<script>
var kjlSjUrl = ''
var shopUrl = ''
var ybjUrl = ''
var ybjbsUrl = ''
import { getSmsCode } from '@/common/api/common'
import { videoList as getVideoList, getYbjbz } from '@/common/api/self'
import { getToken } from '@/utils/auth'
import { Message } from 'element-ui'
export default {
    name: 'datav',
    watch: {
        "$store.state.$userInfo": {
            immediate: true, // 立即执行
            deep: true, // 深度监听复杂类型内变化
            handler: function (newVal, oldVal) {
                // console.log('监听用户信息变化：', newVal)
                if (newVal) {
                    this.loginName = newVal.name ? newVal.name : newVal.phone
                    // this.head = newVal.headImage ? newVal.headImage : require('@/assets/img/head.png')
                    this.hasLogin = true
                    setTimeout(() => {
                        // console.log('延时点击')
                        // this.$refs.bgIframe.dispatchEvent(new MouseEvent("click"));
                        // bgIframe.window.click()
                        // console.log('点击完成')
                    }, 10000)
                } else { // 没有用户信息，则登录
                    this.loginName = '请登录账号'
                    this.hasLogin = false
                    this.$store.dispatch('LoginOut')
                }
            }
        },
        "$store.state.$kjlToken": {
            immediate: true, // 立即执行
            handler: function (newVal, oldVal) {
                // console.log('监听酷家乐token：', newVal)
                if (newVal) {
                    kjlSjUrl = 'https://www.kujiale.com/v/auth?accesstoken=' + newVal + '&dest=4'
                    ybjbsUrl = 'https://www.kujiale.com/pub/saas/workbench/design/all?accesstoken=' + newVal
                } else {
                    this.loginName = '请登录账号'
                    this.hasLogin = false
                    this.$store.dispatch('LoginOut')
                    kjlSjUrl = 'https://www.kujiale.com/v/auth'
                    ybjbsUrl = 'https://www.kujiale.com/pub/saas/workbench/design/all'
                }
                this.sjUrl = kjlSjUrl

                let token = getToken()
                let h5ShopUrl = ''
                // console.log('token：', token)
                if (token) {
                    shopUrl = 'https://tshop.taiyiyunjia.com/?token=' + encodeURIComponent(token) // 测试
                    shopUrl = 'https://shop.nankangyunjia.com/?token=' + encodeURIComponent(token) // 正式
                    h5ShopUrl = 'https://tshop.taiyiyunjia.com/h5/#/?token=' + encodeURIComponent(token) // 测试h5商城
                    h5ShopUrl = 'https://shop.nankangyunjia.com/h5/#/?token=' + encodeURIComponent(token) // 正式h5商城
                    ybjUrl = 'https://kuxiaoju-custom.kulchao.com/museum/#/CloudShop/show/museum/8c44ca1c829f5e6b84146ec7dec743af' // 测试
                    ybjUrl = 'https://kuxiaoju-app.kulchao.com/cloud-shop/#/show/2a479e4a415cceba261ac3b40e67b9a0/material' // 正式

                } else {
                    shopUrl = 'https://tshop.taiyiyunjia.com/'
                    shopUrl = 'https://shop.nankangyunjia.com/' // 正式
                    h5ShopUrl = 'https://tshop.taiyiyunjia.com/h5/#/' // 测试h5商城
                    h5ShopUrl = 'https://shop.nankangyunjia.com/h5/#/' // 正式h5商城
                    ybjUrl = 'https://kuxiaoju-custom.kulchao.com/museum/#/CloudShop/show/museum/8c44ca1c829f5e6b84146ec7dec743af' // 测试
                    ybjUrl = 'https://kuxiaoju-app.kulchao.com/cloud-shop/#/show/2a479e4a415cceba261ac3b40e67b9a0/material' // 正式
                }
                this.shopUrl = h5ShopUrl
                this.$nextTick(() => {
                    this.initMenu()
                })
            }
        }
    },
    data() {
        return {
            hasLoginBg: require('@/assets/img/haslogin-bg.png'),
            notLoginBg: require('@/assets/img/new-bg.png'),
            src: 'https://www.720yun.com/vr/5d3jO5wavk5',
            sysSrc: '',
            head: require('@/assets/img/head.png'),
            loginName: '请登录账号',
            hasLogin: false,
            formData: {
                type: 2, // 1是注册，2是登录
                phone: '',
                verificationCode: ''
            },
            countDown: 0,
            timer: null,
            menuBall: require('@/assets/img/jjxz-ball.png'),
            showBall: true,
            showSys: false,
            showFull: false,
            showVideo: false,
            loadBtn: false,
            sysH5: false, // 系统框是否h5
            hideInfo: true,
            loadLoginBtn: false,
            loadLogoutBtn: false,
            menus: [
                {
                    name: '家居小镇', id: 1, type: 'iframe', ballUrl: require('@/assets/img/jjxz-ball.png'), url: 'https://www.720yun.com/vr/890jO5ymOf6', level: 1, children: [
                        { name: '家居美学馆', type: 'iframe', id: 'jjmxg', url: 'https://www.720yun.com/vr/e71jO5wnew5', level: 2, children: [] },
                        { name: '家居会展中心', type: 'iframe', id: 'jjhzzx', url: 'https://www.720yun.com/vr/e12jO5wnek1', level: 2, children: [] },
                        { name: '城市客厅', type: 'iframe', id: 'cskt', url: 'https://www.720yun.com/vr/baajO5wnen5', level: 2, children: [] },
                        { name: '客家围屋', type: 'iframe', id: 'kjww', url: 'https://www.720yun.com/vr/5bdjO5wneO5', level: 2, children: [] },
                        { name: '十八坊', type: 'iframe', id: 'sbf', url: 'https://www.720yun.com/vr/bd7jO5wnvu3', level: 2, children: [] },
                    ]
                },
                {
                    name: '家居展馆', type: 'iframe', ballUrl: require('@/assets/img/jjzg-ball.png'), id: 2, url: 'https://www.720yun.com/vr/ad6jO5mmta6', level: 1, children: [
                        {
                            name: '1F 南康本土品牌', type: 'iframe', id: '1fnkbtpp', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5752713', level: 2, children: []
                        },
                        {
                            name: '2F 国内家居龙头', type: 'iframe', id: '2fgnjjlt', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5752713', level: 2, children: [
                                { name: '左右沙发', type: 'iframe', id: 'zysf', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5765896', level: 3, children: [] },
                                { name: '姿在家', type: 'iframe', id: 'zzj', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5765897', level: 3, children: [] },
                                { name: '团团园', type: 'iframe', id: 'tty', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5765903', level: 3, children: [] },
                                { name: '傲觅', type: 'iframe', id: 'aomi', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5765994', level: 3, children: [] },
                                { name: '圣蒂斯堡', type: 'iframe', id: 'sdsb', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5765995', level: 3, children: [] },
                                { name: '不下家居', type: 'iframe', id: 'bxjj', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5766001', level: 3, children: [] },
                                { name: '联邦家居', type: 'iframe', id: 'lbjj', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5766006', level: 3, children: [] },
                                { name: '朗朝', type: 'iframe', id: 'langchao', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5766007', level: 3, children: [] },
                                { name: '高乐美', type: 'iframe', id: 'gml', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5766013', level: 3, children: [] },
                            ]
                        },
                        {
                            name: '3F 国际品牌', type: 'iframe', id: '3fgjpp', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5752713', level: 2, children: [
                                { name: '尚品宅配', type: 'iframe', id: 'spzp', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832350', level: 3, children: [] },
                                { name: '欧派', type: 'iframe', id: 'oupai', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832351', level: 3, children: [] },
                                { name: '欧莱雅', type: 'iframe', id: 'ouly', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832352', level: 3, children: [] },
                                { name: '马格马', type: 'iframe', id: 'mgm', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832353', level: 3, children: [] },
                                { name: '格力华为', type: 'iframe', id: 'glhw', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832354', level: 3, children: [] },
                                { name: '百能', type: 'iframe', id: 'bn', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832355', level: 3, children: [] },
                            ]
                        },
                        { name: '4F 设计师联盟', type: 'iframe', id: '4fsjslm', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832356', level: 2, children: [] },
                        { name: '5F 家居元宇宙馆', type: 'iframe', id: '5fjjyyzg', url: 'https://www.720yun.com/vr/febjO5ymey7', level: 2, children: [] },
                    ]
                },
                {
                    name: '家装设计', id: 3, ballUrl: require('@/assets/img/jzsj-ball.png'), type: 'iframe', url: '', level: 1, children: [
                        { name: '样板间浏览', type: 'sys', id: 'ybjll', url: 'https://kuxiaoju-custom.kulchao.com/museum/#/CloudShop/show/museum/8c44ca1c829f5e6b84146ec7dec743af', level: 2, children: [] },
                        { name: '样板间设计', type: 'sys', id: 'ybjsj', url: kjlSjUrl, level: 2, children: [] },
                        { name: '样板间布设', type: 'sys', id: 'ybjbs', url: 'https://www.kujiale.com/pub/saas/workbench/design/all', level: 2, children: [] }
                    ]
                },
                {
                    // name: '家居商城', id: 4, ballUrl: require('@/assets/img/jjsc-ball.png'), type: 'sys', url: 'https://shop.nankangyunjia.com/', level: 1, children: []
                    name: '家居商城', id: 4, ballUrl: require('@/assets/img/jjsc-ball.png'), type: 'sys', url: shopUrl, level: 1, children: []
                },
                {
                    name: '家居藏品', id: 5, ballUrl: require('@/assets/img/jjcp-ball.png'), type: 'sys', platform: 'h5', url: 'http://t-dc-m.unispace.vip/pages/market/index', level: 1, children: [

                    ]
                },
                {
                    name: '家居视频', id: 6, ballUrl: require('@/assets/img/jjsp-ball.png'), type: 'video', url: '', level: 1, children: [

                    ]
                },
            ],
            curTopIndex: 1,
            curSubIndex: undefined,
            topMenus: [],
            subMenus: [],
            showSub: false,
            showYbjbz: false,
            chooseItem: {},
            sameLevel: [], // 同级
            parendItem: {}, // 上级
            videoList: [
                // { name: '视频1', url: 'http://39.101.171.164/source/dangjian/huanjing.mp4' },
            ],
            showYbjbzIfr: false,
            ybjbzSrc: '',
            ybjbzList: [
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
                { name: '产品1', url: 'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg' },
            ],
            shopUrl: '',
            sjUrl: '',
        }
    },
    created() {
        this.init()
    },
    mounted() {

    },
    destroyed() {
        clearInterval(this.timer)
        this.timer = null
    },
    methods: {
        async init() {
            // 判断是否有token
            let token = getToken()
            if (token) {
                this.$store.dispatch('GetUserInfo')
            } else {
                return;
            }

            this.initMenu()

            // iframe通信
            let that = this
            window.addEventListener('message', function (e) {
                if (e.origin == 'https://www.kujiale.com') {
                    console.log('酷家乐通信：', e)
                    if (typeof e.data == 'string' && e.data.indexOf('{') >= 0) { // 可转对象的json字符串
                        let data = JSON.parse(e.data)
                        console.log('iframe通信数据data:', data)
                        console.log('返回的数据是字符串')
                        // if (data == 'KIO') {
                        //     Message({
                        //         message: '账号已在其他地方登录！即将退出登录',
                        //         type: 'error',
                        //         duration: 2.5 * 1000
                        //     })
                        //     setTimeout(() => {
                        //         that.logout()
                        //     }, 2500)
                        // } 
                        if (data.action == "kjl_user_status_changed") {
                            if (!data.data.login) {
                                Message({
                                    message: '账号已在其他地方登录！即将退出登录',
                                    type: 'error',
                                    duration: 2.5 * 1000
                                })
                                setTimeout(() => {
                                    that.logout()
                                }, 2500)
                            }
                        }
                    }
                }
                // console.log('iframe通信数据data:', JSON.parse(e.data))
            }, false)

            // this.topMenus = this.menus.map(item => {
            //     return item
            // })
            // let item = this.topMenus.find(e => {
            //     return e.id == this.curTopIndex
            // })
            // // console.log('初始化：', item)
            // this.src = item.url

            return;
            this.subMenus = item && item.children && item.children.length ? item.children : []
            if (this.subMenus && this.subMenus.length) {
                this.showSub = true
            }
        },
        initSys() {

            console.log(window.location.href)
            if (self.frameElement && self.frameElement.tagName == "sysIframe") {
                console.log('在iframe中')
                // alert('在iframe中');
                window.open(window.location.href)

                parent.location.reload(true);
                var index = parent.layer.getFrameIndex(window.name);
                parent.layer.close(index);
            }
        },
        initMenu() {
            // console.log('初始化菜单')
            this.menus = [
                {
                    name: '家居小镇', id: 1, type: 'iframe', ballUrl: require('@/assets/img/jjxz-ball.png'), url: 'https://www.720yun.com/vr/890jO5ymOf6', level: 1, children: [
                        { name: '家居美学馆', type: 'iframe', id: 'jjmxg', url: 'https://www.720yun.com/vr/e71jO5wnew5', level: 2, children: [] },
                        // { name: '家居美学馆', type: 'iframe', id: 'jjmxg', url: 'https://1b3u4wh0h.wasee.com/wt/1b3u4wh0h', level: 2, children: [] },
                        { name: '家居会展中心', type: 'iframe', id: 'jjhzzx', url: 'https://www.720yun.com/vr/e12jO5wnek1', level: 2, children: [] },
                        { name: '城市客厅', type: 'iframe', id: 'cskt', url: 'https://www.720yun.com/vr/baajO5wnen5', level: 2, children: [] },
                        { name: '客家围屋', type: 'iframe', id: 'kjww', url: 'https://www.720yun.com/vr/5bdjO5wneO5', level: 2, children: [] },
                        { name: '九井十八厅', type: 'iframe', id: 'sbf', url: 'https://www.720yun.com/vr/bd7jO5wnvu3', level: 2, children: [] },
                    ]
                },
                {
                    name: '家居展馆', type: 'iframe', ballUrl: require('@/assets/img/jjzg-ball.png'), id: 2, url: 'https://www.720yun.com/vr/ad6jO5mmta6', level: 1, children: [
                        {
                            name: '1F 家居体验馆', type: 'iframe', id: '1fnkbtpp', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5752713', level: 2, children: []
                        },
                        {
                            name: '2F 家具品牌馆', type: 'iframe', id: '2fgnjjlt', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5752713', level: 2, children: [
                                { name: '左右沙发', type: 'iframe', id: 'zysf', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5765896', level: 3, children: [] },
                                { name: '姿在家', type: 'iframe', id: 'zzj', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5765897', level: 3, children: [] },
                                { name: '团团园', type: 'iframe', id: 'tty', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5765903', level: 3, children: [] },
                                { name: '傲觅', type: 'iframe', id: 'aomi', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5765994', level: 3, children: [] },
                                { name: '圣蒂斯堡', type: 'iframe', id: 'sdsb', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5765995', level: 3, children: [] },
                                { name: '不下家居', type: 'iframe', id: 'bxjj', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5766001', level: 3, children: [] },
                                { name: '联邦家居', type: 'iframe', id: 'lbjj', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5766006', level: 3, children: [] },
                                { name: '朗朝', type: 'iframe', id: 'langchao', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5766007', level: 3, children: [] },
                                { name: '高乐美', type: 'iframe', id: 'gml', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5766013', level: 3, children: [] },
                            ]
                        },
                        {
                            name: '3F 整装材料品牌馆', type: 'iframe', id: '3fgjpp', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5752713', level: 2, children: [
                                { name: '尚品宅配', type: 'iframe', id: 'spzp', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832350', level: 3, children: [] },
                                { name: '欧派', type: 'iframe', id: 'oupai', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832351', level: 3, children: [] },
                                { name: '欧莱雅', type: 'iframe', id: 'ouly', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832352', level: 3, children: [] },
                                { name: '马格马', type: 'iframe', id: 'mgm', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832353', level: 3, children: [] },
                                { name: '格力华为', type: 'iframe', id: 'glhw', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832354', level: 3, children: [] },
                                { name: '百能', type: 'iframe', id: 'bn', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832355', level: 3, children: [] },
                            ]
                        },
                        { name: '4F 设计师联盟', type: 'iframe', id: '4fsjslm', url: 'https://www.720yun.com/vr/ad6jO5mmta6?s=5832356', level: 2, children: [] },
                        { name: '5F 家居元宇宙馆', type: 'iframe', id: '5fjjyyzg', url: 'https://www.720yun.com/vr/febjO5ymey7', level: 2, children: [] },
                    ]
                },
                {
                    name: '家装设计', id: 3, ballUrl: require('@/assets/img/jzsj-ball.png'), type: 'iframe', url: '', level: 1, children: [
                        { name: '样板间浏览', type: 'sys', id: 'ybjll', url: ybjUrl, level: 2, children: [] },
                        { name: '样板间布设', type: 'sys', id: 'ybjbs', url: ybjbsUrl, level: 2, children: [] },
                        { name: '样板间设计', type: 'sys', id: 'ybjsj', url: kjlSjUrl, level: 2, children: [] },
                    ]
                },
                {
                    // name: '家居商城', id: 4, ballUrl: require('@/assets/img/jjsc-ball.png'), type: 'sys', url: 'https://shop.nankangyunjia.com/', level: 1, children: []
                    name: '家居商城', id: 4, ballUrl: require('@/assets/img/jjsc-ball.png'), type: 'sys', url: shopUrl, level: 1, children: []
                },
                {
                    name: '家居藏品', id: 5, ballUrl: require('@/assets/img/jjcp-ball.png'), type: 'sys', platform: 'h5', url: 'http://t-dc-m.unispace.vip/pages/market/index', level: 1, children: [

                    ]
                },
                {
                    name: '家居视频', id: 6, ballUrl: require('@/assets/img/jjsp-ball.png'), type: 'video', url: '', level: 1, children: [

                    ]
                },
            ]

            this.topMenus = this.menus.map(item => {
                return item
            })
            let item = this.topMenus.find(e => {
                return e.id == this.curTopIndex
            })
            this.src = item.url
            // console.log('一级菜单：', this.topMenus)
        },
        resetFormData() {
            this.formData = {
                type: 2, // 1是注册，2是登录
                phone: '',
                verificationCode: ''
            }
        },
        getBgi(url) {
            return 'url(' + url + ')'
        },
        headErr(e) {
            console.log('头像加载失败：', e)
        },
        getVideoList() { // 获取视频列表
            let query = {
                pageSize: 9,
                pageNum: 1,
                isEnabled: 0
            }
            getVideoList(query).then(res => {
                if (res.rows && res.rows.length) {
                    this.videoList = res.rows
                } else {
                    this.videoList = []
                }
            }).catch(err => {
                this.videoList = []
                console.log('获取视频列表失败：', err)
            })
        },
        // 发送验证码
        sendCode() {
            if (!this.formData.phone || this.formData.phone.length != 11) {
                return this.$message.error('请填写正确的手机号');
            }
            this.loadBtn = true
            getSmsCode({ phone: this.formData.phone }).then(res => {
                this.$message.success('发送成功');
                if (this.countDown <= 0) {
                    this.countDown = 60
                    this.timer = setInterval(() => {
                        this.countDown--
                        if (this.countDown <= 0) {
                            clearInterval(this.timer)
                            this.timer = null
                        }
                    }, 1000)
                }
                this.loadBtn = false
            }).catch(err => {
                this.loadBtn = false
                console.log('获取验证码失败：', err)
            })
        },
        // 注册登录
        register() {
            if (!this.formData.phone || this.formData.phone.length != 11) {
                return this.$message.error('请填写正确的手机号');
            }
            if (!this.formData.verificationCode) {
                return this.$message.error('请填写验证码');
            }
            this.loadLoginBtn = true
            // console.log('store:', this.$store)
            this.$store.dispatch('Login', this.formData).then(res => {
                this.$message.success(this.formData.type == 1 ? '注册成功' : '登录成功');
                this.$store.dispatch('GetUserInfo').then(resUser => {
                    this.loadLoginBtn = false
                    this.curTopIndex = 1
                    this.curSubIndex = undefined
                    this.subMenus = []
                    this.topMenus = []
                    this.showSub = false // 隐藏下级
                    this.initMenu()
                }).catch(errUser => {
                    console.log('获取用户信息失败：', errUser)
                    this.loadLoginBtn = false
                })
            }).catch(err => {
                console.log('注册失败：', err)
                this.loadLoginBtn = false
            })
            // login(this.formData).then(res => {

            // }).catch(err => {
            //     console.log('注册失败：', err)
            // })
        },
        // 退出登录
        logout() {
            this.loadLogoutBtn = true
            this.$store.dispatch('LoginOut').then(res => {
                console.log('退出登录成功')
                this.hideInfo = true
                this.loadLogoutBtn = false
                this.showSys = false
                this.resetFormData()
            }).catch(err => {
                this.loadLogoutBtn = false
            })
        },
        // 点击菜单
        handlerNav(type, item, index) {
            this.chooseItem = item
            if (type == 'top') { // 点击一级
                this.curSubIndex = undefined // 清空二级选中
                if (index == this.curTopIndex) { // 选择的是选中的菜单
                    this.subMenus = []
                    if (this.showSub == true) { // 已显示二级
                        this.topMenus = []
                        this.showSub = false // 隐藏下级
                        // 找同级
                        this.sameLevel = []
                        this.findSame(this.menus, item.level)
                        // console.log('找同级：', this.sameLevel)
                        if (item.level == 1) { // 最顶级
                            this.topMenus = this.sameLevel
                        } else {
                            this.parendItem = {}
                            this.findParent(this.menus, item.id, {})
                            // console.log('找上级：', this.parendItem)
                            this.curTopIndex = this.parendItem.id
                            this.topMenus.push(this.parendItem)
                            this.curSubIndex = index
                            if (this.parendItem.children && this.parendItem.children.length) {
                                this.subMenus = this.parendItem.children
                                this.showSub = true
                            }
                        }
                    } else {
                        if (item.children && item.children.length) {
                            this.showSub = true // 显示下级
                            this.subMenus = item.children
                        }
                    }
                } else {
                    this.curTopIndex = index
                    if (item.children && item.children.length) {
                        this.showSub = true // 显示下级
                        this.subMenus = item.children
                    }
                }
            } else { // 点击二级
                // console.log('点击二级：', item)
                if (index == this.curSubIndex) { // 选择的是选中的菜单

                } else {
                    this.curSubIndex = index
                    if (item.children && item.children.length) {
                        // 找父级
                        this.parendItem = {}
                        this.findParent(this.menus, item.id, {})
                        // console.log('找上级：', this.parendItem)
                        this.curTopIndex = index
                        this.topMenus = this.parendItem.children

                        this.curSubIndex = undefined // 清空二级选中
                        this.showSub = true // 显示下级
                        this.subMenus = item.children
                    }
                }
            }
            this.showSomething(this.chooseItem)
        },
        showSomething(item) {
            this.showSys = false
            this.showVideo = false
            this.sysH5 = false
            this.showYbjbz = false
            this.showYbjbzIfr = false
            if (item.ballUrl) {
                this.menuBall = item.ballUrl
            }
            if (item.platform && item.platform == 'h5') {
                this.sysH5 = true
            }
            if (item.type == 'iframe') { // 切换背景iframe
                if (item.url != this.src) {
                    this.src = item.url
                }

            } else if (item.type == 'sys') {
                this.src = ''
                if (item.id == 'ybjbs') { // 样板间布置
                    // this.showSys = true
                    // this.showYbjbz = true
                    // return;

                    this.ybjbzList = []
                    getYbjbz({ start: 0, num: 50, appuid: this.$store.state.$userInfo.buyerUserId }).then(res => {
                        // getYbjbz({ start: 0, num: 50, appuid: 3333 }).then(res => {
                        this.showSys = true
                        this.showYbjbz = true
                        if (res.json) {
                            let data = JSON.parse(res.json)
                            console.log('样板间布置数据：', data)
                            if (data.result && data.result.length) {
                                this.ybjbzList = data.result.map(item => {
                                    let obj = {
                                        name: item.name,
                                        url: item.coverPic,
                                        designId: item.designId
                                    }
                                    return obj
                                })
                            }
                        }
                    }).catch(err => {
                        console.log('获取样板间布置：', err)
                    })
                }
                else if (item.url) {
                    this.showSys = true
                    this.showVideo = false
                    if (item.url != this.sysSrc) {
                        this.sysSrc = item.url
                    }
                    // this.initSys()
                }
            } else if (item.type == 'video') {
                this.getVideoList()
                this.src = item.url
                this.showSys = true
                this.showVideo = true
            }
        },
        goToIfr(type, id) {
            let url = ''
            if (type == 1) { // 设计
                url = 'https://yun.kujiale.com/cloud/tool/h5/diy?designid=' + id + '&?accesstoken=' + this.$store.state.$kjlToken + '&dest=5'
            } else if (type == 2) { // 浏览
                url = 'https://yun.kujiale.com/design/' + id + '/show'
            }
            this.ybjbzSrc = url
            this.showYbjbzIfr = true
        },
        backYbjbz() {
            this.showYbjbzIfr = false
            this.ybjbzSrc = ''
        },
        findSame(arr, level) { // 刷选同级
            arr.forEach((e, i) => {
                if (e.level == level) {
                    this.sameLevel.push(e)
                }
                if (e.children && e.children.length) {
                    this.findSame(e.children, level)
                }
            })
        },
        findParent(arr, id, parent) { // 找上级
            arr.forEach((e, i) => {
                if (e.id == id) {
                    this.parendItem = parent
                }
                if (e.children && e.children.length) {
                    this.findParent(e.children, id, e)
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import "@/common/common.scss";

.first-contain-box {
    position: relative;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow: hidden;
    color: #00FFF4;
    background-image: url('@/assets/img/bg.png');
    // background-size: 100% 100%;
    background-size: cover;
    background-position: center;

    div {
        box-sizing: border-box;
    }

    .inline-box {
        margin-left: 2rem;
    }

    .iframe-box {
        border: none;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0%;
    }

    .nav-box {
        width: 100%;
        // height: 50px;
        height: 4.6vh;
        background-color: rgba(36, 67, 123, 0.84);

        .head-box {
            width: 4.6vh;
            height: 4.6vh;
        }

        .nbi-top {
            font-size: 14px;
            color: #91D8F8;
        }

        .nav-box-info {
            margin-left: 10px;
        }

        .nbi-item {
            // height: 24px;
            height: 2.22vh;
            // padding: 0 10px;
            padding: 0.93vh;
            background-color: #609BF4;
            font-size: 14px;
            color: #fff;
        }
    }

    .left-menu-box {
        height: 100%;
        // width: 778px;
        width: 72vh;
        position: absolute;
        top: 0;
        // left: -350px;
        // left: -380px;
        left: -35.2vh;
        display: flex;
        // justify-content: flex-end;
        align-items: center;
        transition-property: left;
        transition-duration: 0.7s;
        z-index: 100;

        .menu-ball {
            position: absolute;
            // width: 778px;
            width: 72vh;
            // height: 800px;
            height: 74.07vh;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            .trg-btn {
                cursor: pointer;
                position: absolute;
                // width: 64px;
                width: 6vh;
                // height: 64px;
                height: 6vh;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .lmb-left {
            // width: 380px;
            width: 35.2vh;
            height: 100%;
        }

        .menu-box {
            color: #fff;
            text-align: left;
            // width: 350px;
            width: 32.4vh;
            position: relative;

            .top-menu {
                font-size: 32px;
                font-weight: bold;
                // padding-left: 20px;
                padding-left: 1.85vh;
                // margin-bottom: 16px;
                margin-bottom: 1.48vh;
                cursor: pointer;
            }

            .sub-menu {
                font-size: 28px;
                // padding-left: 85px;
                // padding-left: 53px;
                padding-left: 5vh;
                // margin-bottom: 8px;
                margin-bottom: 0.74vh;
                cursor: pointer;
            }

            .active {
                color: #91D8F8;
            }
        }
    }

    .hide-lmb {
        // left: -746px;
        left: -69.1vh;
    }

    .right-sys-box {
        // width: 1400px;
        width: 129.6vh;
        // height: 800px;
        height: 74.07vh;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        // right: 40px;
        right: 3.7vh;

        border-radius: 8px;
        overflow: hidden;
        transition-property: right, top, width, height;
        transition-duration: 0.7s;
        z-index: 10;

        .iframe-sys-box {
            border: none;
            width: 100%;
            height: 100%;
            background-color: #f4f4f4;
        }

        .video-box {
            width: 100%;
            height: 100%;
            align-items: center;
        }

        .video-box-item {
            width: 33.3%;
            height: 33.3%;
            padding: 15px;
        }

        .video-info {
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: 0.8);
        }
    }

    .hide-sys {
        // right: -1400px;
        right: -129.6vh;
    }

    .full-sys {
        width: 100%;
        height: 100vh;
        // top: -50px;
        top: -4.6vh;
        transform: translateY(0);
        right: 0;
    }

    .h5 {
        // width: 400px !important;
        width: 37.04vh !important;
    }

    .content-box {
        width: 100%;
        // height: calc(100vh - 50px);
        height: 95.4vh;
        position: relative;
    }

    .login-box {
        height: 100%;
        // padding-right: 55px;
        padding-right: 5.1vh;
        position: absolute;
        top: 0;
        right: 0;

        .login-box-inside {
            // background-color: rgba(52, 93, 203, 0.66);
            border-radius: 8px;
            // padding: 70px 80px;
            padding: 6.5vh 7.41vh;
            color: #fff;
            // width: 646px;
            width: 59.8vh;
            // height: 613px;
            height: 56.75vh;
            text-align: left;
            background-image: url('@/assets/img/login-bg.png');
            background-size: 100%;

            .change-type {
                font-size: 15px;
            }

            .mg {
                // margin: 20px 0;
                margin: 1.85vh 0;
            }

            .lbi-title {
                font-size: 32px;
                font-weight: 600;
                // margin-bottom: 40px;
                margin-bottom: 3.7vh;
            }

            .lbi-tag {
                font-size: 18px;
                font-weight: 550;
            }

            .lbi-sld {
                // width: 52px;
                width: 4.8vh;
                // height: 5px;
                height: 0.46vh;
                background-color: #4A8AF7;
                border-radius: 5px;
            }

            .clr {
                color: #A7A7A7;
            }

            .input-box {
                // height: 45px;
                height: 4.17vh;

                .bg {
                    background-color: rgba(96, 155, 244, 0.5);
                    height: 100%;
                    border-radius: 5px;
                    border: none;
                    color: #C9C9C9;
                    font-size: 16px;
                }

                .ib-input {
                    background: none;
                    border: none;
                    font-size: 16px;
                    color: #fff;
                    outline: none;
                }

                input {
                    border: none;
                    outline: none;
                    padding: 0 14px;
                    font-size: 16px;
                    color: #fff;
                }

                input::input-placeholder {
                    color: red;
                }

                input::-webkit-input-placeholder {
                    //兼容WebKit browsers（Chrome的内核）
                    color: #C9C9C9;
                }

                input::-moz-placeholder {
                    //Mozilla Firefox 4 to 18
                    color: #C9C9C9;
                }

                input::-moz-placeholder {
                    //Mozilla Firefox 19+
                    color: #C9C9C9;
                }

                input::-ms-input-placeholder {
                    //Internet Explorer 10+
                    color: #C9C9C9;
                }


                // input[type=password]:focus {
                //     outline: none;
                // }
                .btn-box {
                    // width: 112px;
                    width: 10.37vh;
                    // height: 37px;
                    height: 3.43vh;
                    background-color: #D5D5D5;
                    color: #fff;
                    font-size: 15px;
                    border: none;
                    // border-radius: 40px;
                    border-radius: 3.5px;
                }
            }

            .submit-btn {
                // width: 320px;
                width: 29.63vh;
                // height: 55px;
                height: 5.1vh;
                background-color: rgba(96, 155, 244, 0.66);
                font-size: 16px;
                color: #fff;
                font-weight: 700;
                border-radius: 3px;
                cursor: pointer;
                border: none;
            }
        }
    }

    .iframe-sys-box {
        border: none;
        // transform: scale(0.5)
    }

    .userinfo-box {
        // width: 320px;
        width: 29.63vh;
        height: 100%;
        position: absolute;
        left: 0;
        // top: 50px;
        top: 4.63vh;
        background-color: rgba(0, 0, 0, 0.83);
        transition-property: left;
        transition-duration: 0.7s;
        z-index: 101;
        // padding: 20px 0;
        padding: 1.85vh 0;

        .logout-btn {
            // width: 290px;
            width: 26.85vh;
            // height: 55px;
            height: 5.1vh;
            background-color: #91D8F8;
            font-size: 16px;
            color: #24437B;
            border: none;
            font-weight: bold;
        }
    }

    .hide-userInfo {
        // left: -321px;
        left: -29.64vh;
    }

    .full-bg {
        background-color: #fff !important;
    }

    @media screen and (max-width: 1400px) {
        .nbi-top {
            font-size: 10px !important;
        }

        .nbi-item {
            font-size: 10px !important;
        }

        .bg {
            font-size: 12px !important;
        }

        .lbi-title {
            font-size: 28px !important;
        }

        .top-menu {
            font-size: 22px !important;
        }

        .sub-menu {
            font-size: 18px !important;
        }

        .logout-btn {
            font-size: 12px !important;
        }
    }

    @media screen and (max-width: 1000px) {
        .nbi-top {
            font-size: 8px !important;
        }

        .nbi-item {
            font-size: 8px !important;
        }

        .change-type {
            font-size: 10px !important;
        }

        .bg {
            font-size: 10px !important;
        }

        .lbi-tag {
            font-size: 12px !important;
        }

        .lbi-title {
            font-size: 22px !important;
        }

        .top-menu {
            font-size: 18px !important;
        }

        .sub-menu {
            font-size: 14px !important;
        }

        .logout-btn {
            font-size: 10px !important;
        }
    }
}</style>