import request from '@/utils/request'

// const baseURL = 'http://39.103.197.187:9010'
// const baseURL = 'http://39.101.177.197:9010/metaverse-business'
const baseURL = 'https://thome.api.taiyiyunjia.com'

// 注册
export function register(data) {
    return request({
        baseURL,
        url: '/metaverse-business/userInfo/register',
        method: 'post',
        data
    })
}

// 登录
export function login(data) {
    return request({
        baseURL,
        url: '/metaverse-business/userInfo/token',
        method: 'post',
        data
    })
}

// 视频列表
export function videoList(query) {
    return request({
        baseURL,
        url: '/cms-manage/contextVideo/newsList',
        method: 'get',
        headers: {
            isToken: false,
        },
        params: query
    })
}

// 获取样板间布置
export function getYbjbz(data) {
    return request({
        baseURL,
        url: '/metaverse-business/userInfo/getUserAnswer',
        method: 'post',
        data
    })
}
