import Cookies from 'js-cookie'

const TokenKey = 'jjyyzToken'
const UserInfoKey = 'jjyyzUserInfo'
const KujialeTokenKey = 'kjl-Token'
const Domain = 'taiyiyunjia.com'

export function getToken() {
    return Cookies.get(TokenKey)
    return Cookies.get(TokenKey, { path: '/', domain: Domain })
}

export function setToken(token) {
    // return Cookies.set(TokenKey, token)
    return Cookies.set(TokenKey, token, { expires: 6 })
    return Cookies.set(TokenKey, token, { path: '/', domain: Domain })
}

export function removeToken() {
    return Cookies.remove(TokenKey)
    return Cookies.remove(TokenKey, { path: '/', domain: Domain })
}

// 获取用户信息缓存
export function getUserInfo() {
    let userinfo = Cookies.get(UserInfoKey)
    return userinfo ? JSON.parse(userinfo) : null
}

// 设置用户信息缓存
export function setUserInfo(userInfo) {
    return Cookies.set(UserInfoKey, userInfo)
}

// 移除用户信息缓存
export function removeUserInfo() {
    return Cookies.remove(UserInfoKey)
}

// 获取酷家乐token
export function getKjlToken() {
    return Cookies.get(KujialeTokenKey)
}

// 设置酷家乐token
export function setKjlToken(token) {
    return Cookies.set(KujialeTokenKey, token)
}