import Vue from 'vue'
import Vuex from 'vuex'

import { setToken, getUserInfo as getStoreUserInfo, setUserInfo, removeToken, removeUserInfo, setKjlToken, getKjlToken } from '@/utils/auth'
import { login, getUserInfo } from '@/common/api/common'
import { login as selfLogin, register as SelfRegister } from '@/common/api/self'

Vue.use(Vuex) // vue的插件机制

const store = new Vuex.Store({
    state: {
        $userInfo: getStoreUserInfo(),
        $kjlToken: getKjlToken()
    },
    mutations: {
        SET_USER: (state, userInfo) => {
            state.$userInfo = userInfo
        },
        SET_KJL_TOKEN: (state, token) => {
            state.$kjlToken = token
        }
    },
    actions: {
        // 登录
        Login({ commit }, userInfo) {
            const { phone, verificationCode, type } = userInfo
            return new Promise((resolve, reject) => {
                login({ phone: phone, verificationCode: verificationCode, type }).then(res => {
                    const { data } = res
                    // 注册自己平台
                    SelfRegister({ appuid: data.buyerUserId, name: 's' + data.phone }).then(selfRigRes => {
                        // 登录自己平台
                        selfLogin({ dest: 5, appuid: data.buyerUserId }).then(selfLoginRes => {
                            setToken(data.token)
                            setKjlToken(selfLoginRes.json)
                            commit('SET_KJL_TOKEN', selfLoginRes.json)
                            resolve(res)
                        }).catch(selfLoginErr => {
                            reject(selfLoginErr)
                        })
                    }).catch(selfRigErr => {
                        if (selfRigErr.code == 402) { // 已注册
                            // 登录自己平台
                            selfLogin({ dest: 5, appuid: data.buyerUserId }).then(selfLoginRes => {
                                setToken(data.token)
                                setKjlToken(selfLoginRes.json)
                                commit('SET_KJL_TOKEN', selfLoginRes.json)
                                resolve(res)
                            }).catch(selfLoginErr => {
                                reject(selfLoginErr)
                            })
                        } else {
                            reject(selfRigErr)
                        }
                    })
                }).catch(err => {
                    if (err.code == 21001) { // 未注册商城
                        // 去注册
                        login({ phone: phone, verificationCode: verificationCode, type: 1 }).then(res => {
                            const { data } = res
                            // 注册自己平台
                            SelfRegister({ appuid: data.buyerUserId, name: 's' + data.phone }).then(selfRigRes => {
                                // 登录自己平台
                                selfLogin({ dest: 5, appuid: data.buyerUserId }).then(selfLoginRes => {
                                    setToken(data.token)
                                    setKjlToken(selfLoginRes.json)
                                    commit('SET_KJL_TOKEN', selfLoginRes.json)
                                    resolve(res)
                                }).catch(selfLoginErr => {
                                    reject(selfLoginErr)
                                })
                            }).catch(selfRigErr => {
                                if (selfRigErr.code == 402) { // 已注册
                                    // 登录自己平台
                                    selfLogin({ dest: 5, appuid: data.buyerUserId }).then(selfLoginRes => {
                                        setToken(data.token)
                                        setKjlToken(selfLoginRes.json)
                                        commit('SET_KJL_TOKEN', selfLoginRes.json)
                                        resolve(res)
                                    }).catch(selfLoginErr => {
                                        reject(selfLoginErr)
                                    })
                                } else {
                                    reject(selfRigErr)
                                }
                            })
                        }).catch(err => {
                            reject(err)
                        })
                    } else {
                        reject(err)
                    }
                })
            })
        },
        // 获取用户信息
        GetUserInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getUserInfo().then(response => {
                    const { data } = response
                    let newData = JSON.stringify(data)
                    setUserInfo(newData)
                    commit('SET_USER', data)
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        // 退出登录
        LoginOut({ commit }) {
            return new Promise((resolve, reject) => {
                removeToken()
                removeUserInfo()
                commit('SET_USER', null)
                resolve()
            })
        }
    }
})

export default store