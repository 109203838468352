import axios from 'axios'
import { tansParams } from '@/common/func'
import { Message} from 'element-ui'
import { getToken } from '@/utils/auth'
import store from '@/store'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'
const baseURL = process.env.VUE_APP_BASE_API
// const baseURL = 'http://192.168.8.112:8080'  // 测试服
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded'
  },
  // 超时
  timeout: 50000
})

// request拦截器
service.interceptors.request.use(config => {
  // console.log('请求头：', config.headers)
  // 是否需要设置 token
  let isToken = config.headers.isToken === false ? false : true
  config.headers['Authorization'] = isToken ? getToken() : '' // 让每个请求携带自定义token 请根据实际情况自行修改

  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  // console.log('返回的数据：', res)
  // 未设置状态码则默认成功状态
  const code = res.data.code || 200;
  // 获取错误信息
  // const msg = errorCode[code] || res.data.msg || errorCode['default']
  const message = res.data.message || '接口报错'
  // 二进制数据则直接返回
  if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
    return res.data
  }
  if (code == 401 || code == 20004  || code == 20005 || code == 21005 || code == 21015) { // 权限验证不通过，重新登录
    store.dispatch('LoginOut') // 退出登录
    return Promise.reject(message)
  } else if (code != 200) {
    if (code == 402 || code == 21001) { // 已注册 | 未注册
      return Promise.reject(res.data)
    } else {
      Message({
        message: message,
        type: 'error',
        duration: 3 * 1000
      })
      return Promise.reject(message)
    }
  } else {
    return res.data
  }
},
  error => {
    console.log('122err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    }
    else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    }
    else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    return Promise.reject(error)
  }
)

export default service
